import "./App.css";
import TaxCalculator from "./components/TaxCalculator";

function App() {
  return (
    <>
      <TaxCalculator />
    </>
  );
}

export default App;
